import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaFlexWrapperElement = class extends PolymerElement {
  static get is() {
    return 'jha-flex-wrapper';
  }

  static get template() {
    return html`
    <style>
      :host {
        display: flex;
        align-items: center;
      }
      :host([space-between]) {
        justify-content: space-between;
      }
      :host([flex-start]) {
        justify-content: flex-start;
      }
    </style>
    <slot></slot>
  `;
  }
};

customElements.define(window.JhaFlexWrapperElement.is, window.JhaFlexWrapperElement);
export default window.JhaFlexWrapperElement;

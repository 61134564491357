import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaGridElement = class extends PolymerElement {
  static get is() {
    return 'jha-grid';
  }

  static get template() {
    return html`
    <style>
      :host {
        display: flex;
        padding: 36px 0 40px;
        box-sizing: border-box;
      }
      @supports(padding: max(0px)) {
        @media only screen
          and (min-device-width: 320px)
          and (max-device-width: 480px)
          and (-webkit-min-device-pixel-ratio: 2)
          and (orientation: landscape) {
          :host {
            padding-left: min(20px, env(safe-area-inset-left)) !important;
            padding-right: min(20px, env(safe-area-inset-right)) !important;
          }
        }
      }
      :host ::slotted(*) {
        box-sizing: border-box;
      }
      ::slotted(.grid-3-sm),
      ::slotted(.grid-5-sm),
      ::slotted(.grid-6-sm),
      ::slotted(.grid-7-sm),
      ::slotted(.grid-9-sm),
      ::slotted(.grid-6-md),
      ::slotted(.grid-5-md),
      ::slotted(.grid-7-md),
      ::slotted(.grid-2-md),
      ::slotted(.grid-3-md),
      ::slotted(.grid-4-md),
      ::slotted(.grid-8-md),
      ::slotted(.grid-9-md),
      ::slotted(.grid-10-md),
      ::slotted(.grid-12-md),
      ::slotted(.grid-4-lg),
      ::slotted(.grid-5-lg),
      ::slotted(.grid-7-lg),
      ::slotted(.grid-8-lg) {
        flex-basis: 100%;
        max-width: 100%;
        padding: 0 8px;
      }

      ::slotted(.grid-2-xs) {
        flex-basis: 16.66667%;
        max-width: 16.66667%;
      }
      ::slotted(.grid-3-xs) {
        flex-basis: 25%;
        max-width: 25%;
      }
      ::slotted(.grid-4-xs) {
        flex-basis: 33.33337%;
        max-width: 33.33337%;
      }
      ::slotted(.grid-5-xs) {
        flex-basis: 41.66667%;
        max-width: 41.66667%;
      }
      ::slotted(.grid-6-xs) {
        flex-basis: 50%;
        max-width: 50%;
        padding: 0 8px;
      }
      ::slotted(.grid-7-xs) {
        flex-basis: 58.33333%;
        max-width: 58.33333%;
      }
      ::slotted(.grid-8-xs) {
        flex-basis: 66.66667%;
        max-width: 66.66667%;
      }
      ::slotted(.grid-9-xs) {
        flex-basis: 75%;
        max-width: 75%;
      }
      ::slotted(.grid-10-xs) {
        flex-basis: 83.33333%;
        max-width: 83.33333%;
      }
      @media (min-width: 740px) {
        ::slotted(.grid-2-md) {
          flex-basis: 16.66667%;
          max-width: 16.66667%;
        }
        ::slotted(.grid-3-md) {
          flex-basis: 25%;
          max-width: 25%;
        }
        ::slotted(.grid-4-md) {
          flex-basis: 33.33337%;
          max-width: 33.33337%;
        }
        ::slotted(.grid-5-md){
          flex-basis: 41.66667%;
          max-width: 41.66667%;
        }
        ::slotted(.grid-6-md) {
          flex-basis: 50%;
          max-width: 50%;
        }
        ::slotted(.grid-7-md){
          flex-basis: 58.33333%;
          max-width: 58.33333%
        }
        ::slotted(.grid-8-md) {
          flex-basis: 66.66667%;
          max-width: 66.66667%;
        }
        ::slotted(.grid-9-md) {
          flex-basis: 75%;
          max-width: 75%;
        }
        ::slotted(.grid-10-md) {
          flex-basis: 83.33333%;
          max-width: 83.33333%;
        }
      }
      @media (min-width: 480px) and (max-width: 739px) {
        :host {
          padding: 30px 0 40px;
        }
        ::slotted(.grid-3-sm) {
          flex-basis: 25%;
          max-width: 25%;
        }
        ::slotted(.grid-5-sm) {
          flex-basis: 41.66667%;
          max-width: 41.66667%;
        }
        ::slotted(.grid-6-sm) {
          flex-basis: 50%;
          max-width: 50%;
        }
        ::slotted(.grid-7-sm) {
          flex-basis: 58.33333%;
          max-width: 58.33333%;
        }
        ::slotted(.grid-9-sm) {
          flex-basis: 75%;
          max-width: 75%;
        }
      }
      @media (min-width: 993px) {
        :host {
          padding: 36px 0 40px;
        }
        ::slotted(.grid-4-lg) {
          flex-basis: 33.33337%;
          max-width: 33.33337%;
        }
        ::slotted(.grid-5-lg) {
          flex-basis: 41.66667%;
          max-width: 41.66667%;
        }
        ::slotted(.grid-7-lg) {
          flex-basis: 58.33333%;
          max-width: 58.33333%;
        }
        ::slotted(.grid-8-lg) {
          flex-basis: 66.66667%;
          max-width: 66.66667%;
        }
      }
      @media (max-width: 739px) {
        :host {
          overflow: hidden;
          padding-top: 16px;
          flex-wrap: wrap;
        }
      }
    </style>
    <slot></slot>
  `;
  }
};

customElements.define(window.JhaGridElement.is, window.JhaGridElement);
export default window.JhaGridElement;

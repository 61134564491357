import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaCardPlatformElement = class extends PolymerElement {
  static get is() {
    return 'jha-card-platform';
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        background-color: #ffffff;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,.24),0 1px 4px rgba(0,0,0,.12);
        border-radius: 6px;
        margin-bottom: 16px;
        margin-left: auto;
        margin-right: auto;
      }
      :host ::slotted(header) {
        font-size: 16px;
        font-weight: 500;
        color: var(--jha-text-dark);
        padding: 24px 24px 16px 0;
        margin-left: 24px;
        line-height: 21px;
        border-bottom: 1px solid var(--jha-border-color);
      }
      :host ::slotted(article) {
        --default-padding: 24px 24px 24px 0;
        margin: 0 0 24px 24px;
        padding: var(--jha-card-platform-article-padding, var(--default-padding));
      }
      :host ::slotted(footer) {
        margin-left: 24px;
        padding: 16px 24px 16px 0;
        font-size: 16px;
        text-align: right;
      }
      @media (max-width: 991px) {
        :host ::slotted(header) {
          padding: 16px 16px 12px 0;
          margin-left: 16px;
        }
        :host ::slotted(article) {
        margin: 0 0 16px 16px;
        padding: 24px 16px 16px 0;
        }
        :host ::slotted(footer) {
          padding: 12px 16px 12px 0;
          margin-left: 16px;
        }
        :host([single-column]) ::slotted(header),
        :host([single-column]) ::slotted(article),
        :host([single-column]) ::slotted(footer),
        :host([single-column]) ::slotted(jha-list-item) {
          margin-left: 24px;
          padding-right: 24px;
        }
      }
      @media (min-width: 740px) {
        :host {
          max-width: 550px;
        }
        :host([wide]) {
          max-width: none;
        }
      }
      @media (max-width: 739px) {
        :host([reset]) {
          max-width: none;
          border-radius: 0;
          margin-left: -16px;
          margin-right: -16px;
          box-shadow: none;
          position: relative;
        }
      }
    </style>
    <slot></slot>
  `;
  }
};

customElements.define(window.JhaCardPlatformElement.is, window.JhaCardPlatformElement);
export default window.JhaCardPlatformElement;
